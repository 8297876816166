import Will from '../img/p_homepage/golf.jpg'
import MishSt from '../img/p_homepage/mission.jpg'
import Jaden from '../img/p_homepage/jaden.jpg'
import Andre from '../img/p_homepage/beach1.jpg'
function HomePhotos() {
  return (
    <div id="homepage" class="photos">
      <div class="top picture-row">
        <img class="img-ls" src={Will} alt="Will at Golf"/>
        <img class="img-ls" src={MishSt} alt="Mission Street"/>
      </div>
      <div class="home_info">
        <span>i take pictures sometimes.</span>
      </div>
      <div class="bottom picture-row">
        <img class="img-ls" src={Jaden} alt="Jaden"/>
        <img class="img-ls" src={Andre} alt="Andre"/>
      </div>
    </div>
  )
}

export default HomePhotos