import React, { useState, useEffect } from 'react';

const Typewriter = ({text, delay}) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    
    if (currentIndex < text.length) {
      let actualDelay = text[currentIndex] === ' ' ? delay * 3 : delay + 10*Math.abs(currentIndex%8);
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, actualDelay);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, text]);
  return <span class="big-name">{currentText}</span>;
};

export default Typewriter;