import img0 from '../img/p_boat/image0.jpg'
import img1 from '../img/p_boat/image1.jpg'
import img2 from '../img/p_boat/image2.jpg'
import img3 from '../img/p_boat/image3.jpg'
import img4 from '../img/p_boat/image4.jpg'
import img5 from '../img/p_boat/image5.jpg'
import img6 from '../img/p_boat/image6.jpg'
import img7 from '../img/p_boat/image7.jpg'
import img8 from '../img/p_boat/image8.jpg'
import img9 from '../img/p_boat/image9.jpg'
import img11 from '../img/p_boat/image11.jpg'
import img12 from '../img/p_boat/image12.jpg'
import img14 from '../img/p_boat/image14.jpg'

function BoatPhotos() {
  return (
    <div id="beach" class="photos">
    <div class="picture-row">
      <img class="img-ls"  src={img7} alt="andre and cam"/>
      <img class="img-ls"  src={img8} alt="i always take pictures of lucas"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"   src={img2} alt="goyls"/>
      <img class="img-vt"   src={img0} alt="andre and narah"/>
      <img class="img-vt"   src={img11} alt="ashton saw me"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"   src={img3} alt="mau and daena"/>
      <img class="img-vt"   src={img14} alt="leon"/>
      <img class="img-vt"   src={img12} alt="jaden"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"   src={img4} alt="conrad"/>
      <img class="img-vt"   src={img9} alt="more jaden"/>
      <img class="img-vt"   src={img5} alt="oxgora"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img1} alt="jose"/>
      <img class="img-ls"   src={img6} alt="black gentlemen"/>
    </div>
  </div>
  )
}

export default BoatPhotos