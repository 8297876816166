import './css/Music.css';
import React, { useEffect} from "react";

function Music() {
  useEffect(() => {
    document.title = "jwln sux";  
  }, []);
  return (
    <div>
      
      <span style={{width: '100%', textAlign: 'center'}}>
      hi, i'm jwln. i make music
      here's a cool one!
      </span>

      <iframe style={{borderRadius:"12px"}} 
              src="https://open.spotify.com/embed/track/2Ju95XyhV2SvqP4WMyHfse?utm_source=generator" 
              width="100%" 
              height="352" 
              frameBorder="0" 
              allowfullscreen="" 
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
              loading="lazy">
              </iframe>
    <iframe  
            src="https://open.spotify.com/embed/album/3QcFxzp8WlefKlo69jSpvO?utm_source=generator" 
            width="100%" 
            height="352" 
            frameBorder="0" 
            allowfullscreen="" 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy">
            </iframe>

    </div>
  );
}

export default Music;