import './css/Photos.css';
import React, { useState, useEffect } from 'react';
import HomePhotos from './photos/HomePhotos'
import PartyPhotos from './photos/PartyPhotos'
import RingPhotos from './photos/RingPhotos'
import OtherPhotos from './photos/OtherPhotos';
import ContactMe from './ContactMe'
import BeachPhotos from './photos/BeachPhotos';
import BoatPhotos from './photos/BoatPhotos';
import CatPhotos from './photos/CatPhotos';
import FratPhotos from './photos/FratPhotos';
import GolfPhotos from './photos/GolfPhotos';

function PhotosHome() {
  const [activeIdx, setActiveIdx] = useState(0)
  const [activePortIdx, setActivePortIdx] = useState(0)
  const photoPages = [<HomePhotos/>, <RingPhotos/>, <PartyPhotos/>, <OtherPhotos/>, <ContactMe/>, <BeachPhotos/>, <BoatPhotos/>, <CatPhotos/>, <FratPhotos/>, <GolfPhotos/>]
  function coloredName(text, num){
    return (<div>{activeIdx !== num ? <span>{text}</span> : <span id='colored'>{text}</span>}</div>)
  }
  useEffect(() => {
    document.title = "photos!";  
  }, []);
  return (
    
    <div class="mainpage">
      <div class="leftbar">
      <div class="name">
        <span> julian hamelberg</span>
      </div>
      <div class="navbar">
        <ul>
          <li onClick={()=> setActiveIdx(0)}>{coloredName('home', 0)}</li>
          <li onClick={()=> setActivePortIdx(1-activePortIdx)}>portraits+
          </li>
          <ul id="extra_portraits" style={activePortIdx ? {display: 'block'} : {display: 'none'}}>
              <li onClick={()=> setActiveIdx(5)}>{coloredName('beach', 5)}</li>
              <li onClick={()=> setActiveIdx(6)}>{coloredName('boat', 6)}</li>
              <li onClick={()=> setActiveIdx(7)}>{coloredName('cat', 7)}</li>
              <li onClick={()=> setActiveIdx(8)}>{coloredName('frat', 8)}</li>
              <li onClick={()=> setActiveIdx(9)}>{coloredName('golf', 9)}</li>
              <li onClick={()=> setActiveIdx(1)}>{coloredName('ring', 1)}</li>
            </ul>
          <li onClick={()=> setActiveIdx(2)}>{coloredName('parties', 2)}</li>
          <li onClick={()=> setActiveIdx(3)}>{coloredName('other', 3)}</li>
          <li onClick={()=> setActiveIdx(4)}>{coloredName('contact', 4)}</li>
        </ul>
      </div>
      <div class="backtomain">
        <a href="/">back to main</a>
      </div>
    </div>
    {photoPages[activeIdx]}
    </div>
  );
}

export default PhotosHome