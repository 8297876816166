import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Home'
import Music from './Music'
import Photos from './PhotosHome'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home/>}/>
        <Route path="/music" element={<Music/>}/>
        <Route path="/photos" element={<Photos/>}/>
      </Routes>
    </BrowserRouter>  
  );
}

export default App;
