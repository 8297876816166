import img1 from '../img/frat/image1.jpg'
import img2 from '../img/frat/image2.jpg'
import img3 from '../img/frat/image3.jpg'
import img4 from '../img/frat/image4.jpg'
import img5 from '../img/frat/image5.jpg'
import img6 from '../img/frat/image6.jpg'
import img7 from '../img/frat/image7.jpg'
import img8 from '../img/frat/image8.jpg'
import img9 from '../img/frat/image9.jpg'
import img10 from '../img/frat/image10.jpg'
import img11 from '../img/frat/image11.jpg'

function FratPhotos() {
  return (
    <div id="frat" class="photos">
    <div class="picture-row">
      <img class="img-ls"  src={img8} alt="group1"/>
      <img class="img-ls"  src={img9} alt="group2"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"  src={img1} alt="man1"/>
      <img class="img-vt"  src={img2} alt="man2"/>
      <img class="img-vt"  src={img4} alt="man3"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"  src={img6} alt="man4"/>
      <img class="img-ls"  src={img7} alt="man5"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"  src={img3} alt="man6"/>
      <img class="img-ls"  src={img5} alt="man7"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img10} alt="grad group"/>
      <img class="img-ls"   src={img11} alt="grad1"/>
    </div>
  </div>
  )
}

export default FratPhotos