import img0 from '../img/others/image0.jpg'
import img1 from '../img/others/image1.jpg'
import img2 from '../img/others/image2.jpg'
import img3 from '../img/others/image3.jpg'
import img4 from '../img/others/image4.jpg'
import img5 from '../img/others/image5.jpg'
import img7 from '../img/others/image7.jpg'

function OtherPhotos() {
  return (
    <div id="others" class="photos">
    <div class="picture-row">
      <img class="img-vt"  src={img0} alt="andre"/>
      <img class="img-vt"  src={img5} alt="lucas catch"/>
      <img class="img-vt"  src={img1} alt="mike"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"  src={img3} alt="boys on rock"/>
      <img class="img-ls"  src={img4} alt="crew on rock"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img2} alt="albert"/>
      <img class="img-ls"   src={img7} alt="birdo hit"/>
    </div>
  </div>
  )
}

export default OtherPhotos