import img0 from '../img/parties/image0.jpg'
import img1 from '../img/parties/image1.jpg'
import img2 from '../img/parties/image2.jpg'
import img3 from '../img/parties/image3.jpg'
import img4 from '../img/parties/image4.jpg'
import img5 from '../img/parties/image5.jpg'

function PartyPhotos() {
  return (
    <div id="parties" class="photos">
    <div class="picture-row">
      <img class="img-ls"  src={img0} alt="img1"/>
      <img class="img-ls"  src={img1} alt="img1"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"  src={img2} alt="img2"/>
      <img class="img-ls"  src={img5} alt="img2"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img3} alt="img3"/>
      <img class="img-ls"   src={img4} alt="img3"/>
    </div>
  </div>
  )
}

export default PartyPhotos