
function ContactMe() {
  const w = '100%';
  return (
    <div style={{width:w, display:'flex', alignContent:'center', alignItems:'center', textAlign:'center'}}>
      <span>
        Nothing to see here. Message me on instagram @prodbyjwln
      </span>
    </div>
  )
}

export default ContactMe