import img0 from '../img/p_beach/image0.jpg'
import img1 from '../img/p_beach/image1.jpg'
import img2 from '../img/p_beach/image2.jpg'
import img3 from '../img/p_beach/image3.jpg'
import img4 from '../img/p_beach/image4.jpg'
import img5 from '../img/p_beach/image5.jpg'
import img6 from '../img/p_beach/image6.jpg'
import img8 from '../img/p_beach/image8.jpg'
import img11 from '../img/p_beach/image11.jpg'
import img12 from '../img/p_beach/image12.jpg'
import img14 from '../img/p_beach/image14.jpg'
import img15 from '../img/p_beach/image15.jpg'

function BeachPhotos() {
  return (
    <div id="beach" class="photos">
    <div class="picture-row">
      <img class="img-ls"  src={img14} alt="mario #ad"/>
      <img class="img-ls"  src={img8} alt="balancing boys"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"   src={img2} alt="Nick"/>
      <img class="img-vt"   src={img0} alt="pink Jaden"/>
      <img class="img-vt"   src={img11} alt="wistful"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"  src={img3} alt="albert"/>
      <img class="img-ls"  src={img12} alt="arman frisbee"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"   src={img4} alt="jose"/>
      <img class="img-vt"   src={img15} alt="lucas frisbee"/>
      <img class="img-vt"   src={img5} alt="dankwa and andre"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img1} alt="Nick"/>
      <img class="img-ls"   src={img6} alt="andre vibing"/>
    </div>
  </div>
  )
}

export default BeachPhotos