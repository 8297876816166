import './css/Home.css';
import React, { useEffect} from "react";
import instabw from './img/instagram-bw.png'
import linkedinbw from './img/linkedin-bw.png'
import githubbw from './img/github-bw.png'
import Typewriter from './Typewriter'
import Resume from './misc/resume.pdf';


function Home() {
  useEffect(() => {
    document.title = "julian hamelberg";  
  }, []);
  return (
    <div className="Home">
      <header className="App-header">
        <Typewriter text="julian hamelberg" delay={100}/>
        <div className="other-links">
          <ul class="links">
            <li><a className="textName" href="/photos" bis_skin_checked="1">photos</a></li>
            <li><a className="textName" href="https://linktr.ee/jwln" bis_skin_checked="1">music</a></li>
            <li><a className="textName" target="_blank" rel="noopener noreferrer" href={Resume} bis_skin_checked="1">resume</a></li>
          </ul>
        </div>
        
        <div>
          <ul className="link-icons">
            <li><a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/jshjulian" bis_skin_checked="1"> <img className="imgicon" alt="linkedin" src={linkedinbw}/>
                </a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/prodbyjwln" bis_skin_checked="1"> <img className="imgicon" alt="instagram" src={instabw}/>
            </a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/jshjulian" bis_skin_checked="1"> <img className="imgicon" alt="github" src={githubbw}/>
            </a></li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Home;
