import img0 from '../img/p_golf/image0.jpg'
import img1 from '../img/p_golf/image1.jpg'
import img3 from '../img/p_golf/image3.jpg'
import img4 from '../img/p_golf/image4.jpg'
import img5 from '../img/p_golf/image5.jpg'
import img6 from '../img/p_golf/image6.jpg'
import img7 from '../img/p_golf/image7.jpg'

function GolfPhotos() {
  return (
    <div id="golf" class="photos">
    <div class="picture-row">
      <img class="img-ls"  src={img0} alt="img0"/>
      <img class="img-ls"  src={img1} alt="img1"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"  src={img6} alt="img6"/>
      <img class="img-vt"  src={img5} alt="img5"/>
      <img class="img-vt"  src={img7} alt="img7"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img3} alt="img3"/>
      <img class="img-ls"   src={img4} alt="img4"/>
    </div>
  </div>
  )
}

export default GolfPhotos