import img0 from '../img/p_ring/image0.jpg'
import img1 from '../img/p_ring/image1.jpg'
import img2 from '../img/p_ring/image2.jpg'
import img4 from '../img/p_ring/image4.jpg'
import img5 from '../img/p_ring/image5.jpg'
import img8 from '../img/p_ring/image8.jpg'
import img9 from '../img/p_ring/image9.jpg'
import img10 from '../img/p_ring/image10.jpg'
import img11 from '../img/p_ring/image11.jpg'

function RingPhotos() {
  return (
    <div id="ring" class="photos">
    <div class="picture-row">
      <img class="img-ls"  src={img9} alt="raj"/>
      <img class="img-ls"  src={img11} alt="derrick"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"  src={img0} alt="mau"/>
      <img class="img-vt"  src={img2} alt="guy"/>
      <img class="img-vt"  src={img1} alt="andre"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img5} alt="jose and crew"/>
      <img class="img-ls"   src={img4} alt="leon and crew"/>
    </div>
    <div class="picture-row">
      <img class="img-ls"   src={img8} alt="lucas"/>
      <img class="img-ls"   src={img10} alt="jose"/>
    </div>
  </div>
  )
}

export default RingPhotos