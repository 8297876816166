import img1 from '../img/p_cat/image1.jpg'
import img2 from '../img/p_cat/image2.jpg'
import img3 from '../img/p_cat/image3.jpg'
import img4 from '../img/p_cat/image4.jpg'

function CatPhotos() {
  return (
    <div id="cat" class="photos">
    <div class="picture-row">
      <img class="img-ls"  src={img1} alt="img1"/>
      <img class="img-ls"  src={img3} alt="img3"/>
    </div>
    <div class="picture-row">
      <img class="img-vt"     src={img2} alt="img2"/>
      <img class="img-ls-vt"  src={img4} alt="img4"/>
    </div>
  </div>
  )
}

export default CatPhotos